import Header from "components/Header";
import Footer from "components/Landing/Footer";
import About_Us from "../../assets/img/about-us.webp";
import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
  }, []);


  // console.log("jjbdjbdj")

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          background: "#fff",
        }}
        className="bg-white "
      >
        <Header text={"About Us"} />
        <section className="dark:bg-gray-100 dark:text-gray-800 bg-white flex justify-center align-center lg:py-32">
          <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
            <h2 className="mb-0 text-4xl font-bold leading-none text-center sm:text-5xl text-gray-600 max-lg:pt-28">
              About Us
            </h2>

            <section id="">
              <div className="container-custom">
                <div className="row align-items-center cs_gap_y_40">
                  <>
                    <div className="col-xl-6 wow">
                      <div className="cs_pr_45 text-center">
                        <img src={About_Us} alt="illustartion1" />
                      </div>
                    </div>
                    <div className="col-xl-6 wow">
                      <div className="cs_height_60 cs_height_lg_40"></div>
                      <div className="row cs_gap_y_40">
                        <div className="px-0 pb-4 text-gray-500">
                          <p className="text-gray-800 text-justify lg:text-base">
                            Welcome to gdvpanel.in, your one-stop for all things
                            astrological. As part of Gurudev GD Vashist Jyotish
                            Sansthan, we offer a wide range of astrological
                            remedies to help you unlock the power of the stars
                            and bring positive change to your life and to the
                            life of others. gdvpanel.in is a website of Deep
                            Technoservices LLP and is owned by Gurudev GD
                            Vashist Ji, who is also the owner of Gurudev GD
                            Vashist Jyotish Sansthan. When you're looking for
                            your personal Lal Kitab horoscope (Janam Kundli),
                            you'll find everything you need right here on our
                            website. Our extensive collection includes a variety
                            of high-quality remedies, each chosen for its
                            ability to bring harmony and balance to your life.
                            We believe that astrology has the power to transform
                            lives, and we're dedicated to making it accessible
                            to people around the world. Our team of expert
                            astrologers have a deep understanding of the
                            principles of astrology. That's why we offer a
                            user-friendly website and an easy checkout process.
                            Whether you're a seasoned astrology enthusiast or
                            just starting to explore the wonders of the stars,
                            you'll find everything you need to unlock their
                            power and bring positive change to your life and to
                            the life of others. So why wait? At gdvpanel.in, you
                            can view your personal Lal Kitab horoscope (Janam
                            Kundli) and purchase all astrological remedies
                            suggested by Gurudev GD Vashist, allowing you to
                            benefit from his expertise and experience in the
                            field of astrology. Browse our website and discover
                            the power of the stars for yourself!
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                  {/* ))} */}
                </div>
              </div>
              <div className="cs_height_150 cs_height_lg_75"></div>
            </section>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
