import FooterNew from "components/Footer/FooterNew";
import Header from "components/Header";
import Footer from "components/Landing/Footer";
import React, { useEffect } from "react";
import Return_Refund_img from "../../assets/img/return_refunds.webp";

const ReturnRefunds = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          background: "#fff",
        }}
        className="bg-white "
      >
        <Header text={"About Us"} />
        <section className="dark:bg-gray-100 dark:text-gray-800 bg-white flex justify-center align-center lg:py-32">
          <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
            <h2 className="mb-0 text-4xl font-bold leading-none text-center sm:text-5xl text-gray-600 max-lg:pt-28">
              Return & Refunds
            </h2>
            {/* <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 dark:divide-gray-300">
              <div className="px-0 pb-4 text-gray-500">
                <p className="text-gray-800 text-justify lg:text-base uppercase">
                  Welcome To gdvpanel.in, Your One-Stop For All Things
                  Astrological. As Part Of Gurudev GD Vashist Jyotish Sansthan,
                  We Offer A Wide Range Of Astrological Remedies To Help You
                  Unlock The Power Of The Stars And Bring Positive Change To
                  Your Life And To The Life Of Others. gdvpanel.in Is A Website
                  Of Deep TechnoServices LLP And Is Owned By
                  Gurudev GD Vashist Ji, Who Is Also The Owner Of Gurudev GD
                  Vashist Jyotish Sansthan. When You're Looking For Your
                  Personal Lal Kitab Horoscope (Janam Kundli), You'll Find
                  Everything You Need Right Here On Our Website. Our Extensive
                  Collection Includes A Variety Of High-Quality Remedies, Each
                  Chosen For Its Ability To Bring Harmony And Balance To Your
                  Life. We Believe That Astrology Has The Power To Transform
                  Lives, And We're Dedicated To Making It Accessible To People
                  Around The World. Our Team Of Expert Astrologers Have A Deep
                  Understanding Of The Principles Of Astrology. That's Why We
                  Offer A User-Friendly Website And An Easy Checkout Process.
                  Whether You're A Seasoned Astrology Enthusiast Or Just
                  Starting To Explore The Wonders Of The Stars, You'll Find
                  Everything You Need To Unlock Their Power And Bring Positive
                  Change To Your Life And To The Life Of Others. So Why Wait? At
                  gdvpanel.in, You Can View Your Personal Lal Kitab Horoscope
                  (Janam Kundli) And Purchase All Astrological Remedies
                  Suggested By Gurudev GD Vashist, Allowing You To Benefit From
                  His Expertise And Experience In The Field Of Astrology. Browse
                  Our Website And Discover The Power Of The Stars For Yourself!
                </p>
              </div>
            </div> */}

            <section id="">
              <div className="container-custom">
                <div className="row align-items-center cs_gap_y_40">
                  {/* {Grades[lang].map((item_main, index_main) => ( */}
                  <>
                    <div className="cs_section_heading cs_style_1 text-center col-xl-12">
                      <div className="cs_section_heading cs_style_1">
                        <h2
                          className="cs_section_title mb-0 wow fadeIn"
                          // data-aos="fade-in"
                          // data-aos-duration="800"
                          // data-aos-delay="200"
                        >
                          {/* {item_main.sub_heading} */}
                        </h2>
                        <p className="cs_section_subtitle cs_text_accent">
                          {/* {item_main.heading} */}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-xl-6 wow"
                      // data-aos="fade-right"
                      // data-aos-duration="800"
                      // data-aos-delay="200"
                    >
                      <div className="cs_pr_45 text-center">
                        <img src={Return_Refund_img} alt="illustartion1" />
                      </div>
                    </div>
                    <div
                      // key={index_main}
                      className="col-xl-6 wow"
                      // data-aos="fade-in"
                      // data-aos-duration="800"
                      // data-aos-delay="200"
                    >
                      <div className="cs_height_60 cs_height_lg_40"></div>
                      <div className="row cs_gap_y_40">
                        <div className="px-0 pb-4 text-gray-500">
                          <p className="text-gray-800 text-justify lg:text-base">
                            Refunds/Return cannot be issued on the order of any
                            reports under any circumstances, Panelist is
                            requested to register, purchase or recharge
                            carefully and with full consideration. We request
                            you to please Re-check all the details when entered
                            in the GDV AchrayaPanel. Will not consider refund
                            requests resulting from the incorrect details/data
                            provided by you.
                          </p>
                          <br />
                          <p className="text-gray-800 text-start lg:text-base">
                            For more clarification visit the corporate office at
                          </p>
                          <br />
                          <address className="text-gray-800 text-center lg:text-base">
                            <p className="text-gray-800 text-start lg:text-base">
                              <strong>Deep TechnoServices LLP</strong>
                              <br /> 2<sup>nd</sup> Floor, C-97, Mckenzie Tower,
                              Phase-2,
                              <br />
                              Mayapuri, West Delhi Pin: 110064
                              <br />
                              Email: care@astroscience.in
                              <br />
                              Contact Number: +91-9821397676
                            </p>
                          </address>
                        </div>
                      </div>
                    </div>
                  </>
                  {/* ))} */}
                </div>
              </div>
              <div className="cs_height_150 cs_height_lg_75"></div>
            </section>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ReturnRefunds;
