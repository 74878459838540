import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
//import { logout } from '../../redux/actions/auth'
import { logout } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { ModalHeader } from "react-bootstrap";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { PlainExpired } from "components/Dashboard/PlainExpired";
import { checkplan } from "redux/actions/plan";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const AcharyaNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [notival, setNotival] = React.useState();
  const [color, setColor] = React.useState("navbar-transparent");
  const [textColor, setTextColor] = React.useState("navbar-transparent");
  const history = useHistory();
  const user = localStorage.getItem("user");
  const userDetail = JSON.parse(user);
  const dispatch = useDispatch();
  let acharyaname = "";
  if (userDetail) {
    acharyaname = userDetail["name"];
  } else {
    dispatch(logout());
    localStorage.clear();
    history.push("/login");
  }

  const aid = userDetail ? userDetail["id"] : "";
  let acharyaimg =
    userDetail && userDetail["image"]
      ? userDetail["image"]
      : require("assets/img/default-avatar.png");
  // console.log("sjkldfljsdjklfjklf",acharyaname,acharyaimg);

  const [notification, setNotification] = useState();
  const [loader, setLoader] = useState(true);
  const [style, setStyle] = useState("cont");

  const closeCard = async () => {
    // console.log("You Just Clicked")
    setStyle("cont2");
  };

  const getSubscription = () => {
    if (dispatch(checkplan)) {
      dispatch(checkplan);
    } else {
      dispatch(logout());
    }
  };

  const getAllNotification = async () => {
    // setLoader(true)
    try {
      const body = {
        notificationfor: "acharya",
      };
      const allnotification = await axios.post(
        `${BAS_URL_APPAPI}acharya/shownotification`,
        body
      );
      // console.log("All Notification==============",allnotification.data.data)
      setNotification(allnotification.data.data);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const logout1 = async () => {
    const obj = {
      id: aid,
    };

    try {
      const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
      if (result) {
        dispatch(logout());
      }
    } catch (err) {
      if (err.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };
  // console.log("Loader =====",loader);
  useEffect(() => {
    getAllNotification();
    getSubscription();
  }, []);

  // const dispatch = useDispatch()

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = (noti) => {
    // console.log("Notificatiib id",noti);
    setModalSearch(!modalSearch);
    setNotival(noti);
  };

  return (
    <>
      <Navbar
        className={classNames(
          "navbar-absolute",
          {
            [color]: props.location.pathname.indexOf("full-screen-map") === -1,
          },
          "z-0"
        )}
        expand="lg"
      >
        <Container
          fluid
          className="bg-gradient-to-r from-violet-400 to-pink-400 border-t-none mt-[-2px]"
        >
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />

              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <div className="d-flex justify-content-between align-item-center">
              <div>
                <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                  {props.brandText}
                </NavbarBrand>
              </div>
              <div></div>
            </div>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>

          <Collapse navbar isOpen={collapseOpen}>
            <Nav
              className="ml-auto"
              navbar
              style={{
                display: "flex",
                alignItems: "start !important",
                visibility: "visible",
              }}
            >
              {" "}
              <li style={{ lineHeight: "45px" }}>
                <PlainExpired />
              </li>
              <div className="flex max-lg:flex-col">
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  // className="flex"
                >
                  {/* <div className="h-full pt-1"> */}
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i
                      className="tim-icons icon-bell-55"
                      style={{ color: "#ffffff" }}
                    />
                    <p
                      className="d-lg-none"
                      style={{
                        color: window.innerWidth < 993 ? "#ffffff" : "#000",
                      }}
                    >
                      Notifications
                    </p>
                  {/* </div> */}
                </DropdownToggle>

                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {notification &&
                    notification.map((noti, index) => (
                      <NavLink tag="li">
                        <DropdownItem className="nav-item">
                          <p
                            data-target="#searchModal"
                            style={{
                              maxWidth: "50px",
                              color:
                                window.innerWidth < 993 ? "#ffffff" : "#000",
                            }}
                            data-toggle="modal"
                            id="search-button"
                            onClick={() => toggleModalSearch(noti)}
                            key={index}
                          >
                            {noti.message.substring(
                              0,
                              noti.message.length > 10
                                ? 10
                                : noti.message.length - 1
                            )}
                          </p>
                        </DropdownItem>
                      </NavLink>
                    ))}
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      No notification
                    </DropdownItem>
                  </NavLink>
                  {/* <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Your friend Michael is in town
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another notification
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another one
                    </DropdownItem>
                  </NavLink> */}
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    {/* <img alt="..." src={require("assets/img/mike.jpg")} /> */}
                    {/* <img alt="..." src={require("assets/img/default-avatar.png")} /> */}
                    <img alt="..." src={acharyaimg} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p
                    className="d-lg-none"
                    style={{
                      color: window.innerWidth < 993 ? "#ffffff" : "#000",
                    }}
                  >
                    {acharyaname}
                  </p>
                </DropdownToggle>

                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <Link
                      to="/acharya/myprofile"
                      style={{
                        color: window.innerWidth < 993 ? "#ffffff" : "#000",
                      }}
                    >
                      {" "}
                      <DropdownItem className="nav-item text-dark">
                        Profile
                      </DropdownItem>
                    </Link>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem
                      onClick={() => logout1()}
                      className="nav-item"
                      style={{
                        color: window.innerWidth < 993 ? "#000" : "#000",
                      }}
                    >
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              </div>
            <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader style={{ padding: "10px 15px", background: "#292f343d" }}>
          <h4 className="mb-0">Notification</h4>
          <button
            aria-label="Close"
            className="close p-0"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>

        <div className="modal-body">
          {notival ? <p>{notival.message}</p> : ""}
        </div>
      </Modal>
    </>
  );
};

export default AcharyaNavbar;
