import Header from "components/Header";
import Footer from "components/Landing/Footer";
import React, { useEffect } from "react";
const TermsConditions = () => {

  useEffect(()=> {
    window.scroll({
      top: 0, 
      left: 1, 
      behavior: 'smooth'
    });
  },[])

  return (
    <>
      <Header text={"Terms & Conditions"} />
      <section className="dark:bg-gray-100 dark:text-gray-800 bg-white flex justify-center align-center lg:py-32">
        <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
           <h2 className="mb-12 text-4xl font-bold leading-none text-center sm:text-5xl text-gray-600 max-lg:pt-28">
           Terms & Conditions
          </h2>
          <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 dark:divide-gray-300">
            <div className="px-0 pb-4 text-gray-500">
              <p className="text-gray-800 text-justify lg:text-base ƒ">
                Please Read Carefully the Terms and Conditions before using the
                GDV Panel by using our website and our services, you agree to be
                bound by the terms and conditions. If you do not agree to these
                terms and conditions, you should not use this website.
              </p>
            </div>
          </div>
          <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 dark:divide-gray-300">
            {/* <details> */}

            <div className="grid gap-10 md:gap-8 sm:p-3 md:grid-cols-2 lg:grid-cols-1 lg:px-0 xl:px-0">
              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    UPDATION
                  </h5>

                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  The Website may update/amend/modify these Terms of Usage from
                  time to time. The User is responsible to check the Terms of
                  Usage periodically to remain in compliance with these terms.{" "}
                </p>
              </div>
              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    USER CONSENT
                  </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  By accessing the Website and using it, you (“Member”, “You”,
                  “Your”) indicate that you understand the terms and
                  unconditionally & expressly consent to the Terms of Usage of
                  this Website. The User is advised to read the Terms of Usage
                  carefully before using or registering on the Website or
                  accessing any material, information or services through the
                  Website. Your use and continued usage of the Website
                  (irrespective of the amendments made from time to time) shall
                  signify your acceptance of the terms of usage and your
                  agreement to be legally bound by the same.{" "}
                </p>
              </div>
              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    USE OF CONTENT
                  </h5>

                <p className="mt-1 text-gray-800 text-justify lg:text-base ">
                  All of the content on gdvpanel.in, Including but not limited
                  to Text, Graphics, Logos, Brand Images, Guru ji’s Image,
                  Videos and software, is the property Astroscience.in or its
                  content suppliers and is protected by National & International
                  Copyright laws.{" "}
                </p>
              </div>
              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    USE OF WEBSITE
                  </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base ">
                  You agree to use gdvpanel.in for lawful purposes and in a way
                  that does not infringe on the rights of, restrict, or inhibit
                  anyone else’s use and enjoyment of the website. You also agree
                  not to use the website in any manner that could damage,
                  disable, overburden, company image, its brand image or impair
                  the website or interfere with any third party’s use and
                  enjoyment of the website.
                </p>
              </div>
              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    ACCOUNT AND REGISTRATION
                  </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base ">
                  In Order the use certain feature of gdvpanel.in, you may be
                  required to register and create an account. You are
                  responsible for maintaining the confidentiality of your
                  Acharya Panel account and for restricting access to your
                  computer or on other electronic devices.
                </p>
                <p className="mt-1 text-gray-800 lg:text-base">
                  computer or on other electronic devices. If panelist account
                  is inactive for continuous three months after the end of
                  subscription plan, it shall be considered as suspended and
                  require initiating the registration process again. Balance
                  amount in your panel if any will be forfeited. If panelist
                  agree to this than only proceed for registration.
                </p>
              </div>
              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    PRIVACY POLICY
                  </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  The User hereby consents, expresses and agrees that the User
                  has read and fully understand the Privacy Policy of the
                  Website. The User further consents that the terms and contents
                  of such Privacy policy is acceptable to the User inclusive of
                  any update/alteration/change made and duly displayed on the
                  GDVpanel.{" "}
                </p>
              </div>

              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    DISCLAIMER OF WARRANTIES
                  </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  gdvpanel.in Provides its service “As-it-is” and without any
                  warranty or condition, express, implied or statutory. We make
                  no Representations or warranties of any kind, either expressed
                  or implied, including without limitation warranties of
                  merchantability, fitness for a particular purpose, or
                  non-infringement of intellectual property.{" "}
                </p>
              </div>

              <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    LIMITATION OF LIABILITY
                  </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  The User expressly understands and agrees that, to the maximum
                  extent permitted by applicable law, the Website does not
                  provide warranties for the service.{" "}
                </p>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  To the maximum extent permitted by applicable law, the
                  GDVpanel will have no liability related to User content
                  arising under intellectual property rights, liable, privacy,
                  publicity, obscenity or other laws. The GDVpanel also
                  disclaims all liability with respect to the misuse, loss,
                  modification or unavailability of any User content. The
                  GDVpanel will not be liable for any loss that the User may
                  incur as a consequence of unauthorized use of their account or
                  account information in connection with the Website or any
                  services or materials, either with or without the User’s
                  knowledge. The Website has endeavored to ensure that all the
                  information on the Website is correct, but the Website neither
                  warrants nor makes any representations regarding the quality,
                  accuracy or completeness of any data, information, product or
                  service.{" "}
                </p>
              </div>
              <div>
                <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                  INDEMNIFICATION
                </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  The User shall indemnify, defend and hold harmless the
                  gdvpanel.in and its parent, subsidiaries, affiliates,
                  officers, directors, employees, suppliers, consultants and
                  agents from any and all third party claims, liability, damages
                  and/or costs (including, but not limited to, attorney’s fees)
                  arising from Your use of the Services, Your violation of the
                  Privacy Policy or these Terms of Service, or Your violation of
                  any third party's rights, including without limitation,
                  infringement by You or any other user of Your account of any
                  intellectual property or other right of any person or entity.
                  These Terms of Service will inure to the benefit of Website’s
                  successors, assigns, and licensees.{" "}
                </p>
              </div>
              <div>
                <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                  PROPRIETARY RIGHTS TO CONTENT
                </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  The User acknowledges that the Content, including but not
                  limited to text, software, music, sound, photographs, video,
                  graphics or other material contained in sponsor advertisements
                  or distributed via email, commercially produced information
                  presented to Member by the GDVpanel Website, its suppliers,
                  and/or advertisers, is protected by copyrights, trademarks,
                  service marks, patents and/or other proprietary rights and
                  laws. The User is not permitted to copy, use, reproduce,
                  distribute, perform, display, or create derivative works from
                  the Content unless expressly authorized by the Website, its
                  suppliers, or advertisers. Also, all the content of Acharya
                  Panel rights belongs to Deep TechnoServices LLP. You will not have any right to change or misuse of this
                  content.{" "}
                </p>
              </div>
              <div>
              <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                  MODIFICATION OF TERMS & CONDITIONS
                </h5>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  Deep TechnoServices LLP reserves the rights to
                  modify these terms and conditions at any time without prior
                  notice. Your continued use of the GDVpanel after any such
                  changes constitutes your acceptance of the new terms &
                  conditions, please contact us at{" "}
                  <a href="mailto:care@astroscience.in">
                    {" "}
                    care@astroscience.in
                  </a>
                  .
                </p>
                <p className="mt-1 text-gray-800 text-justify lg:text-base">
                  Corporate office C-97, 2<sup>nd</sup> Floor- Mckenzie Tower, Phase-2,
                  Mayapuri New Delhi- 110064.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsConditions;

const style = {
  container: {
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  content: {
    fontSize: "16px",
    color: "#fff",
    marginTop: "20px",
    textAlign: "left",
  },
};
