import axios from 'axios'
// import { setAlert } from './alert'
import { USER_LOADED, AUTH_ERROR,REGISTER_SUCCESS,REGISTER_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, HOROSCOPE_POST, CHECK_PLAN } from './constants'
import setAuthToken from '../../utils/setAuthToken'
import setAuthUser from '../../utils/setAuthUser'
import { setAlert } from './alert'
import { checkplan } from './plan'
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

//console.log("setAuthTokensetAuthTokensetAuthToken",setAuthToken);



// Keep user loaded persistently
export const loadUser = () => async(dispatch) => {
    if(localStorage.token){
        setAuthToken(localStorage.token)
        console.log(localStorage.token)
    }
   
    try{
        const res = await axios.get(`${BAS_URL_APPAPI}acharya/logedinuser`)
        console.log("Resssssssssssssssss=============",res.data)
        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
        dispatch(checkplan())
        } catch (err) {
            dispatch({
                type: AUTH_ERROR
            })
        }
}

// Registration
export const registration = ({ name,email,contact,country,state,pincode,house, password}) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
   
    const body = JSON.stringify({name,email,contact,country,state,pincode,house, password})

    try{
        const res = await axios.post(`${BAS_URL_APPAPI}registration/registration`, body, config)
        console.log('Register========', res.data.data)
        if(res.data.success === 0){
            throw ('You Have\'nt Fill All The Fields')
        }
        // console.log('res==', res)
        localStorage.setItem('user', JSON.stringify(res.data.data))
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data.data,

            //localStorage.setItem("user", JSON.stringify(response.data));
            
        })

    } catch(err){
        const errors = err
        if(errors){
            dispatch(setAlert(errors, 'primary'))
            console.log(errors)
        }
        dispatch({
            type:REGISTER_FAIL
        })
    }
}

//Login
export const login = ({ email, password}) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
   
    const body = JSON.stringify({email, password})

    try{
        const res = await axios.post(`${BAS_URL_APPAPI}acharya/login`, body, config)
        console.log('Login========', res.data.data)
        if(res.data.success === 0){
            dispatch({
                type: LOGIN_FAIL,
                payload: res.data,
    
                //localStorage.setItem("user", JSON.stringify(response.data));
                
            })
            throw ('Invalid username or password')
        }
        // console.log('res==', res)
        localStorage.setItem('user', JSON.stringify(res.data.data))
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
            //localStorage.setItem("user", JSON.stringify(response.data));
            
        })

        dispatch(loadUser())
    } catch(err){
        const errors = err
        if(errors){
            dispatch(setAlert(errors, 'primary'))
            console.log(errors)
        }
        dispatch({
            type:LOGIN_FAIL
        })
    }
}


export const logout = () => dispatch => {
    dispatch({type: LOGOUT})
    dispatch({type: HOROSCOPE_POST, payload: {}})
}


