import axios from "axios";
import {
  GET_PRODUCTS,
  HOROSCOPE_POST,
  PREDICTION_POST,
  GET_MAHADASHA,
  SELECTED_PRODUCT,
  SELECTED_LANGUAGE,
  QUESTION_POST,
} from "./constants";
import setAuthToken from "../../utils/setAuthToken";
import moment from "moment";
import { logout } from "./auth";
// import Loadingspinner from 'components/Dashboard/Loadingspinner';
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;

export const getInitialData = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  let ProductList;
  // console.log(localStorage.token,"usertokenusertoken")
  try {
    // const result = await axios.post(`${BAS_URL_APPAPI}acharya/apitoken`);
    // const usertoken = result.data.data[0].token;
    const config = {
      headers: {
        authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    };
    const userRole = "Horoscope With Remedy";
    const resp = await axios.get(`${VASHISHT_API_URL}product`, config);
    const product = resp.data.data.filter((el) => el.status === 1);
    if (userRole.includes("Horoscope Without Remedy")) {
      ProductList = product.filter((item) => item.paid == "0");
    } else {
      ProductList = product;
    }

    dispatch({
      type: GET_PRODUCTS,
      payload: { products: ProductList },
    });
  } catch (err) {
    const errors = err;
    if (errors) {
      console.log(errors);
    }
  }
};

export const selectedproduct = (product) => async (dispatch) => {
  dispatch({
    type: SELECTED_PRODUCT,
    payload: { product },
  });
};
export const selectedlanguage = (language) => async (dispatch) => {
  dispatch({
    type: SELECTED_LANGUAGE,
    payload: { language },
  });
};

export const getGocharKundaliInc =
  (dataObj, userdetails1) => async (dispatch) => {
    // const result = await axios.post(`${BAS_URL_APPAPI}acharya/apitoken`);
    // const usertoken = result.data.data[0].token;
    const config = {
      headers: {
        authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    };
    let genrateService = {};
    let store;
    let laganKundali;
    let colorChart;

    const body = JSON.stringify(dataObj);
    const newdata = JSON.parse(body);
    try {
      // let horoscopeDetails = dataObj;
      let horoscopeInput = dataObj;
      const res = await axios.post(`${VASHISHT_API_URL}horoscop`, body, config);

      let prediction = "";
      let upay = res.data.data.upay;
      //chart
      let genrateReport = res.data.data.new_cusp_chart;
      genrateService.genrate = genrateReport;
      //lagan kundali
      let genrateLagan = res.data.data.new_kmv_lagan;
      genrateService.genrateKundali = genrateLagan;
      //kp chart
      let kpGenrate = res.data.data.new_kp_chart;
      genrateService.genrateKp = kpGenrate;
      //shodhit kundali
      let shodhitGenrate = res.data.data.new_shodhitkundali;
      genrateService.genrateshodhit = shodhitGenrate;
      //userdetails
      let gocharKpChart = res.data.data.gochar_kp_chart;
      let userdetails = res.data.data.new_userDEtails;
      genrateService.genuserdetail = userdetails;
      let mahadasha = res.data.data.mahadasha;
      genrateService.genmahadasha = mahadasha;
      let anterdasha = res.data.data.antardasha;
      genrateService.genanterdasha = anterdasha;
      let deskvalue = res.data.data.deskvalue;
      genrateService.genanterdeskvalue = deskvalue;
      let upaychart = res.data.data.upay_chart;
      genrateService.genanterupaychart = upaychart;
      let gocharLagan = res.data.data.gochar_kmv_lagan;
      horoscopeInput.relation = res.data.data.new_userDEtails[0].relation;
      // inLocalStorage();
      let kvhouse = res.data.data.new_kv;
      genrateService.genratekvhouse = kvhouse;
      userdetails1.relation = res.data.data.new_userDEtails[0].relation;

      deskvalue = res.data.data.deskvalue;
      store = res.data.data.new_cusp_chart;
      //lagan kundali
      laganKundali = res.data.data.new_kmv_lagan;
      //kp chart
      colorChart = res.data.data.new_kp_chart;
      //shodhit kundali
      let shodhitkundali = res.data.data.new_shodhitkundali;
      let leve1One = res.data.data.mahadasha;
      for (let index = 0; index < leve1One.length; index++) {
        const item = leve1One[index];
        let arrayOfSigni = [];
        for (let index = 0; index < item.Signi.length; index++) {
          if (index == 3) {
            break;
          }
          const ele =
            item.Signi[index].Signi +
            " " +
            item.Signi[index].Rule +
            " " +
            item.Signi[index].WhichPlanet +
            " | ";
          arrayOfSigni.push(ele);
        }
        let stringOfSigni = arrayOfSigni.toString().replace(/,/g, "");
        leve1One[index].Signi = stringOfSigni.substring(
          0,
          stringOfSigni.length - 2
        );
      }

      console.log("newdatanewdatanewdatanewdata", newdata);
      let finalnew = newdata.finalyear;
      console.log("fsldk;jfsjfklsdfjl Final year", finalnew);
      let vfaldata;
      if (res) {
        let lagankundli = laganKundali;
        let new_kp_chart = kpGenrate;
        const body = {
          age: finalnew,
          new_kp_chart: new_kp_chart,
          new_kmv_lagan: lagankundli,
        };

        let vaeshfal = await axios.post(
          `${BAS_URL_APPAPI}acharya/varshfalkundali`,
          body
        );
        vfaldata = vaeshfal.data.data;
        // console.log("Varshfal data====",vfaldata);
      }

      dispatch(
        getCurrentMahadasha(
          leve1One,
          userdetails1,
          colorChart,
          store,
          horoscopeInput
        )
      );

      dispatch({
        type: HOROSCOPE_POST,
        payload: {
          vfaldata,
          leve1One,
          prediction,
          upay,
          genrateService,
          userdetails1,
          store,
          laganKundali,
          colorChart,
          shodhitkundali,
          ...res.data.data,
        },
      });
    } catch (err) {
      if (err.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };

export const getGocharKundali =
  (allDatanew, dataObj, userdetails1, finalyear) => async (dispatch) => {
    let genrateService = {};
    let store;
    let laganKundali;
    let colorChart;
    //console.log("allDatanewallDatanewallDatanewallDatanew",allDatanew);
    const body = JSON.stringify(allDatanew);
    const newdata = JSON.parse(body);
    // console.log("Body data in horoscope",body);
    // console.log("bodybodybodybodybody new Redux",newdata);
    //console.log("newdatanewdatanewdatanewdatanewdatanewdata redux",newdata);
    try {
      let horoscopeDetails = newdata;
      //console.log("horoscopeDetailshoroscopeDetails",horoscopeDetails);
      let horoscopeInput = newdata;
      //console.log("horoscopeInputhoroscopeInputhoroscopeInput",horoscopeInput);
      let prediction = "";
      let genrateReport = newdata.new_cusp_chart;
      //console.log("genrateReportgenrateReportgenrateReport",genrateReport);
      genrateService.genrate = genrateReport;
      //lagan kundali
      let genrateLagan = newdata.new_kmv_lagan;
      //console.log("genrateLagangenrateLagangenrateLagan",genrateLagan);
      genrateService.genrateKundali = genrateLagan;
      //kp chart
      let kpGenrate = newdata.new_kp_chart;
      //console.log("kpGenratekpGenratekpGenratekpGenrate",kpGenrate);
      genrateService.genrateKp = kpGenrate;
      //shodhit kundali
      let shodhitGenrate = newdata.new_shodhitkundali;
      //console.log("shodhitGenrateshodhitGenrateshodhitGenrate",shodhitGenrate);
      genrateService.genrateshodhit = shodhitGenrate;
      //userdetails
      let gocharKpChart = newdata.gochar_kp_chart;
      let userdetails = newdata.new_userDEtails;
      genrateService.genuserdetail = userdetails;
      let mahadasha = newdata.mahadasha;
      genrateService.genmahadasha = mahadasha;
      let anterdasha = newdata.antardasha;
      genrateService.genanterdasha = anterdasha;
      let deskvalue = newdata.deskvalue;
      genrateService.genanterdeskvalue = deskvalue;
      let upaychart = newdata.upay_chart;
      genrateService.genanterupaychart = upaychart;
      let gocharLagan = newdata.gochar_kmv_lagan;
      // horoscopeInput.relation = newdata.new_userDEtails.relation;
      horoscopeInput.relation = newdata.rotatekundali;
      // inLocalStorage();
      let kvhouse = newdata.new_kv;
      genrateService.genratekvhouse = kvhouse;
      // userdetails1.relation = newdata.new_userDEtails[0].relation;
      userdetails1.relation = newdata.rotatekundali;
      deskvalue = newdata.deskvalue;
      store = newdata.new_cusp_chart;
      //lagan kundali
      laganKundali = newdata.new_kmv_lagan;
      //kp chart
      colorChart = newdata.new_kp_chart;
      //shodhit kundali
      let shodhitkundali = newdata.new_shodhitkundali;
      let leve1One = newdata.mahadasha;
      for (let index = 0; index < leve1One.length; index++) {
        const item = leve1One[index];
        let arrayOfSigni = [];
        for (let index = 0; index < item.Signi.length; index++) {
          if (index == 3) {
            break;
          }
          const ele =
            item.Signi[index].Signi +
            " " +
            item.Signi[index].Rule +
            " " +
            item.Signi[index].WhichPlanet +
            " | ";
          arrayOfSigni.push(ele);
        }
        let stringOfSigni = arrayOfSigni.toString().replace(/,/g, "");
        leve1One[index].Signi = stringOfSigni.substring(
          0,
          stringOfSigni.length - 2
        );
      }

      // console.log('userdetails1', userdetails1)
      let finalnew = finalyear;
      console.log("fsldk;jfsjfklsdfjl Final year", finalnew);
      let vfaldata;
      if (allDatanew) {
        let lagankundli = laganKundali;
        let new_kp_chart = kpGenrate;
        const body = {
          age: finalnew,
          new_kp_chart: new_kp_chart,
          new_kmv_lagan: lagankundli,
        };
        let vaeshfal = await axios.post(
          `${BAS_URL_APPAPI}acharya/varshfalkundali`,
          body
        );
        vfaldata = vaeshfal.data.data;
        console.log("Varshfal data====", vfaldata);
      }
      dispatch(
        getCurrentMahadasha(
          leve1One,
          colorChart,
          userdetails1,
          store,
          horoscopeInput
        )
      );

      dispatch({
        type: HOROSCOPE_POST,
        payload: {
          vfaldata,
          leve1One,
          prediction,
          genrateService,
          store,
          userdetails1,
          laganKundali,
          colorChart,
          shodhitkundali,
          ...newdata,
        },
      });
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };

export const getQuestionAnswerRedux = (dataObj) => async (dispatch) => {
  // const result = await axios.post(`${BAS_URL_APPAPI}acharya/apitoken`);
  // const usertoken = result.data.data[0].token;
  const config = {
    headers: {
      authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    },
  };
  let questionService = {};

  const body = JSON.stringify(dataObj);
  const newdata = JSON.parse(body);
  console.log("New data in prediction====", newdata);
  try {
    // let horoscopeDetails = dataObj;
    // let horoscopeInput = dataObj;
    const res = await axios.post(
      `${VASHISHT_API_URL}horoscop/kundaliquestion`,
      body,
      config
    );
    console.log("question data======", res);
    let badpl = res.data.data.badpl;
    questionService.badpl = badpl;
    let childpl = res.data.data.childpl;
    questionService.childpl = childpl;
    let foreigntripl = res.data.data.foreigntripl;
    questionService.foreigntripl = foreigntripl;
    let goodpl = res.data.data.goodpl;
    questionService.goodpl = goodpl;
    let healthpl = res.data.data.healthpl;
    questionService.healthpl = healthpl;
    let kp_chart = res.data.data.kp_chart;
    questionService.kp_chart = kp_chart;
    let loveaffairpl = res.data.data.loveaffairpl;
    questionService.loveaffairpl = loveaffairpl;
    let marriagepl = res.data.data.marriagepl;
    questionService.marriagepl = marriagepl;
    let occupationpl = res.data.data.occupationpl;
    questionService.occupationpl = occupationpl;
    let parentspl = res.data.data.parentspl;
    questionService.parentspl = parentspl;
    let questions = res.data.data.questions;
    questionService.questions = questions;
    let vehiclehousepl = res.data.data.vehiclehousepl;
    questionService.vehiclehousepl = vehiclehousepl;
    console.log("QST", res.data.data, questionService);
    dispatch({
      type: QUESTION_POST,
      payload: { question: questionService, ...res.data.data },
    });
  } catch (error) {
    console.log(error);
  }
};

export const horoscopePrediction = (dataObj) => async (dispatch) => {
  // const result = await axios.post(`${BAS_URL_APPAPI}acharya/apitoken`);
  // const usertoken = result.data.data[0].token;
  const config = {
    headers: {
      authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(dataObj);
  try {
    const res5data = await axios.post(
      `${VASHISHT_API_URL}horoscop/preduction`,
      body,
      config
    );
    console.log("Prediction ========", res5data.data.data.prediction);

    let prediction = res5data.data.data.prediction;

    dispatch({
      type: PREDICTION_POST,
      payload: { ...prediction },
    });
  } catch (err) {
    if (err) {
      console.log(err);
    }
  }
};

export const getCurrentMahadasha =
  (mahadashaList, userdetails1, colorChart, store, horoscopeInput) =>
  async (dispatch) => {
    // const result = await axios.post(`${BAS_URL_APPAPI}acharya/apitoken`);
    // const usertoken = result.data.data[0].token;
    const config = {
      headers: {
        authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    };
    try {
      var currentDate = moment().format("DD/MM/YYYY");
      var currentMahadasha = mahadashaList.filter(
        (Map) =>
          moment(currentDate, "DD-MM-YYYY").valueOf() >=
            moment(Map.StartDate, "DD-MM-YYYY").valueOf() &&
          moment(Map.EndDate, "DD-MM-YYYY").valueOf() >=
            moment(currentDate, "DD-MM-YYYY").valueOf()
      );

      let currentLKA = [];
      let predStart = currentMahadasha[0].StartDate;
      let predEnd = currentMahadasha[0].EndDate;
      let planet = currentMahadasha[0].Planet;

      let postData = {
        Dob:
          userdetails1.dob.day +
          "/" +
          userdetails1.dob.month +
          "/" +
          userdetails1.dob.year,
        dasha_start: predEnd,
        dasha_end: predStart,
        dasha_planet: planet,
        kp_chart: colorChart,
        cusp_chart: store,
        tob: userdetails1.tob.hour + "/" + userdetails1.tob.minute,
        type: "35years",
        userDetails: userdetails1,
        showupay: horoscopeInput.showupay ? horoscopeInput.showupay : false,
        gender: horoscopeInput.gender,
        showref: horoscopeInput.showref,
        product: horoscopeInput.product,
      };

      let LKAPlanet = [];
      let planetList = [];

      const body = JSON.stringify(postData);
      const result = await axios.post(
        `${BAS_URL_APPAPI}acharya/35salachakar`,
        body,
        config
      );

      if (result) {
        LKAPlanet = result.data.data.filter(
          (val) =>
            moment(currentDate, "DD-MM-YYYY").valueOf() >=
              moment(val.Start_Date, "DD-MM-YYYY").valueOf() &&
            moment(val.End_Date, "DD-MM-YYYY").valueOf() >=
              moment(currentDate, "DD-MM-YYYY").valueOf()
        );
        planetList = result.data.data.filter(
          (val) => val.Planet == LKAPlanet[0].Planet
        );
        planetList = planetList.slice(1);
        for (let i = 0; i < planetList.length; i++) {
          if (
            moment(currentDate, "DD-MM-YYYY").valueOf() >=
              moment(planetList[i].Start_Date, "DD-MM-YYYY").valueOf() &&
            moment(planetList[i].End_Date, "DD-MM-YYYY").valueOf() >=
              moment(currentDate, "DD-MM-YYYY").valueOf()
          ) {
            let index = i + 1;
            planetList[i].indexVal = index;
            currentLKA.push(planetList[i]);
          }
        }
        currentLKA = currentLKA.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.indexVal === value.indexVal)
        );
      }
      dispatch({
        type: GET_MAHADASHA,
        payload: { currentLKA },
      });
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };
