import React from "react";
import IconBank from "../../assets/landing/img/shop.svg";
import priceBar from "../../assets/landing/img/price_bar.svg";
import setup from "../../assets/landing/img/setup.svg";
import ui from "../../assets/landing/img/ui.svg";
import vector from "../../assets/landing/img/Vector.svg";
import { Anchor } from "react-bootstrap";

const Leading = ({ lang = "eng" }) => {
  const leading = {
    heading: "",
    sub_heading: "Top-Grade Astrology software with Powerful Features",
    eng: [
      {
        heading:
          "Outstanding Experience with GD Vashist Panel: An Advanced Astrological Platform",
        sub_heading:
          "Learn and practice advanced astrology with Gurudev GD Vashist software —  Redefine your journey with the best Kundali software!",
        points: [
          {
            img: IconBank,
            title: "Unlimited Lal Kitab Printouts",
            sub_title:
              "With a premium plan, you will have access to unlimited downloads as well as printouts of Lal Kitab Amrit Vashist Jyotish, along with all the provisions under the basic subscription.",
          },
          {
            img: priceBar,
            title: "Categorized Prediction",
            sub_title:
              "Accessing predictions within our Kundali software dashboard is easy, as they are organized into categories such as health, education, marriage, child and more.",
          },
          {
            img: setup,
            title: "Kundali Based Q/A",
            sub_title:
              "There are up to 20 sections for questions and answers, allowing you to analyze predictions related to career, lucky days, parents, occupation and more.",
          },
          {
            img: ui,
            title: "Generate Kundali with Relative’s Data",
            sub_title:
              "If you do not have your own date of birth or information, there is no need to worry. You can get your kundali using your family member’s details.",
          },
        ],
      },
    ],
   
    hin: [
      {
        heading:
          "जीडी वशिष्ठ पैनल के साथ शीर्ष अनुभव: एक उन्नत ज्योतिषीय प्लेटफ़ॉर्म",
        sub_heading:
          "हमारे कुंडली सॉफ्टवेयरके साथ सीखें,अभ्यास करें और ज्योतिष के क्षेत्र में आगे बढ़ें – सबसे सर्वोत्तम कुंडली सॉफ्टवेयर के साथ अपनी नयी यात्रा को परिभाषित करें।",
        points: [
          {
            img: IconBank,
            title: "सभी के लिए डिज़ाइन किया गया प्लेटफ़ॉर्म",
            sub_title:
              "चाहे आप शुरूआती ज्योतिष छात्र हो या एक अनुभवी ज्योतिषाचार्य या बस एक उत्साही हो , हमारा कुंडली सॉफ्टवेयर आप सभी के लिए आकर्षक होगा , इसकी विशेषताएं हर किसी की जरूरतों को पूरा करते हुए डिज़ाइन की गयी है।",
          },
          {
            img: priceBar,
            title: "लागत-प्रभावी समाधान के रूप में उपलब्ध",
            sub_title:
              "हमारे प्लेटफार्म के साथ-साथ कुंडली सॉफ्टवेयर का उपयोग करके आप अपने ग्राहक की जटिल समस्याओं का समाधान कर सकते है साथ ही लागत-प्रभावी उपाय प्रदान कर सकते हैं।",
          },
          {
            img: setup,
            title: "आसान और उपयोगकर्ता-अनुकूल एप्लिकेशन",
            sub_title:
              "आसान उपयोग और उपयोगकर्ता-अनुकूल इंटरफेस के साथ, आप अपने ज्योतिष अभ्यास को परिपूर्णता के साथ आनंदमय और लाभकारी अनुभव प्राप्त करेंगे।?"
          },
          {
            img: ui,
            title: "स्लिक और सहज डैशबोर्ड डिज़ाइन",
            sub_title: "आसान और सहज आर्किटेक्चर-डिजाइन के कारण, आप इस प्लेटफार्म या डैशबोर्ड के भीतर आसानी से नेविगेट कर सकते हैं, जिससे आप अपने समय की पर्याप्त मात्रा में बचत कर सकते हैं।"
          }
        ],
      },
    ],
  };

  return (
    <section className="cs_business_feature position-relative" id="features">
      <div className="cs_height_100 cs_height_lg_75"></div>
      <div className="container-custom">
        {leading[lang].map((item_main) => (
          <>
            <div className="cs_section_heading cs_style_1 text-center">
              <>
                <h2
                  className="cs_section_title mb-0 wow fadeIn"
                  data-aos="fade-in"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                  {item_main.heading}
                </h2>
                <p
                  className="cs_section_subtitle cs_text_accent wow"
                  data-aos="fade-up"
                >
                  {item_main.sub_heading}
                </p>{" "}
              </>
            </div>
            <div className="cs_height_85 cs_height_lg_60"></div>
            <div
              className="row cs_gap_y_30 wow"
              data-aos="fade-in"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              {item_main.points.map((item, index) => (
                <div key={index} className="col-lg-6 col-xl-3">
                  <div className="cs_iconbox cs_style_1">
                    <div className="cs_iconbox_icon">
                      <img src={item.img} alt="Icon" />
                    </div>
                    <h3 className="cs_iconbox_title">{item.title}</h3>
                    <p className="cs_iconbox_subtitle text-gray-900">
                      {item.sub_title}
                    </p>
                    {/* <a href="#" className="cs_text_btn cs_text_accent flex">
                      Learn More
                      <span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.147 1.75739C10.147 1.28795 9.76649 0.907395 9.29705 0.907394L1.64705 0.907394C1.17761 0.907395 0.797048 1.28795 0.797048 1.75739C0.797048 2.22684 1.17761 2.60739 1.64705 2.60739H8.44705V9.4074C8.44705 9.87684 8.82761 10.2574 9.29705 10.2574C9.76649 10.2574 10.147 9.87684 10.147 9.4074L10.147 1.75739ZM1.41281 10.8437L9.89809 2.35844L8.69601 1.15635L0.210727 9.64163L1.41281 10.8437Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.147 1.75739C10.147 1.28795 9.76649 0.907395 9.29705 0.907394L1.64705 0.907394C1.17761 0.907395 0.797048 1.28795 0.797048 1.75739C0.797048 2.22684 1.17761 2.60739 1.64705 2.60739H8.44705V9.4074C8.44705 9.87684 8.82761 10.2574 9.29705 10.2574C9.76649 10.2574 10.147 9.87684 10.147 9.4074L10.147 1.75739ZM1.41281 10.8437L9.89809 2.35844L8.69601 1.15635L0.210727 9.64163L1.41281 10.8437Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </a> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="cs_featured_shape">
              <img src={vector} alt="vector" />
            </div>
          </>
        ))}
      </div>
      <div className="cs_height_150 cs_height_lg_80"></div>
    </section>
  );
};

export default Leading;
