
import Dashboard from "views/Dashboard.js";
import PaidKundali from "views/pages/PaidKundali";
import Profile from "views/pages/Profile";
import Rechargecredit from "views/pages/Rechargecredit";
import Support from "views/pages/Support";
import Transaction from "views/pages/Transaction";
import Storedkundalitable from "views/pages/Storedkundalitable";
import dashicon from './assets/img/Dashboard.png'
import paidKundli from './assets/img/PaidKundli1.png'
import transaction from './assets/img/Transaction.png'
import savekundli from './assets/img/Savekundli.png'
import recharge from './assets/img/Recharge.png'
import support from './assets/img/Support.png'
import default_ from './assets/img/default-avatar.png'
import viewedkundli from './assets/img/all-viewed-kundli.png'
import Viewedkundali from "./views/pages/Viewedkundali"

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    mini: "D",
    icon:dashicon,
    component: Dashboard,
    layout: "/acharya"
  },
  
  {
    path: "/generated-kundali-pdf",
    name: "Generated kundali pdf",
    mini: "D",
    icon:paidKundli,
    component: PaidKundali,
    layout: "/acharya"
  },
  {
    path: "/viewedkundali",
    name: "All Viewed Kundali",
    mini: "D",
    icon:viewedkundli,
    component: Viewedkundali,
    layout: "/acharya"
  } ,
  {
    path: "/transactions",
    name: "Transactions",
    mini: "D",
    icon:transaction,
    component: Transaction,
    layout: "/acharya"
  },
  {
    path: "/savedkundali",
    name: "Saved Kundali",
    mini: "D",
    icon:savekundli,
    component: Storedkundalitable,
    layout: "/acharya"
  },
  {
    path: "/registration-management",
    name: "Registration management",
    mini: "D",
    icon:recharge,
    component: Rechargecredit,
    layout: "/acharya"
  },
 
  {
    path: "/support",
    name: "Support",
    mini: "D",
    icon:support,
    component: Support,
    layout: "/acharya"
  },
  
  {
    path: "/myprofile",
    name: "Profile",
    icon:default_,
    component: Profile,
    layout: "/acharya"
  },  

];

export default routes;
