import React from "react";

const Loader = () => {
  return (
    <div className="cs_perloader">
      <div className="cs_perloader_in">
        <div className="cs_perloader_dots_wrap">
          <div className="cs_perloader_dots">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
      <span className="cs_perloader_text">Loading...</span>
    </div>
  );
};

export default Loader;
