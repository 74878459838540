import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../assets/img/logo.png";
import { Link, useLocation} from "react-router-dom";

const Header = ({ text }) => {
  const history = useHistory();
  const [activeNav, setActiveNav] = useState(false);
  const [activeAnch, setActiveAnch] = useState("");
  
  const redirectLink  = (mylink) => {
    history.push({
      pathname: '/home',
      state: mylink
    });
  }
  
  console.log(history,"history")


  const closeDrawer = (event, mylink) => {
    setActiveAnch(event);
    setActiveNav(false);
    redirectLink(mylink)
  };

  const closeDrawerLangHindi = (event) => {
    // lang("hin")
    setActiveNav(false);
  };

  const closeDrawerLangEng = (event) => {
    // lang("eng")
    setActiveNav(false);
  };




  return (
    <>
     <header
      className="cs_site_header cs_style_1 cs_text_dark cs_sticky_header cs_medium cs_sticky_active bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0"
      style={{ backgroundColor: "transparent" }}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <div className="shrink-0 mr-4">
              <Link
                to={"/"}
                className="block text-[000] text-2xl font-bold"
                aria-label="Cruip"
              >
                <img src={logo} alt={"GDV PANEL"} width={"70%"} />
              </Link>
            </div>
            <div className="cs_main_header_left w-fit">
              <nav className="cs_nav cs_medium cs_primary_font">
                <ul
                  className={`cs_nav_list cs_onepage_nav relative ${
                    activeNav ? "cs_active" : ""
                  }`}
                >
                  {/* drawer item show in mobile only */}
                  <li className="max-lg:block lg:hidden">
                    <Link
                      to="/registration"
                      className="max-lg:block px-3 text-dark"
                      data-modal="register" 
                    >
                      Register
                    </Link>
                  </li>
                  <li className="max-lg:block lg:hidden">
                    <Link
                      className="cs_header_text_btn cs_modal_btn"
                      to="/login"
                    >
                      Login
                    </Link>
                  </li>
                  {/* drawer item show in mobile only*/}
                  <li 
                  >
                    <Link
                      // to="home"
                      className={activeAnch === 0 ? "active-link" : ""}
                      onClick={() => closeDrawer(0, "home")}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      // to="features"
                      className={activeAnch === 1 ? "active-link" : ""}
                      onClick={() => closeDrawer(1, "features")}
                    >
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link
                      // to="pricing"
                      className={activeAnch === 2 ? "active-link" : ""}
                      onClick={() => closeDrawer(2, "pricing")}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link
                      // to="testimonial"
                      className={activeAnch === 3 ? "active-link" : ""}
                      onClick={() => closeDrawer(3, "testimonial")}
                    >
                      Testimonial
                    </Link>
                  </li>
                  <li>
                    <Link
                      // to="faq"
                      className={activeAnch === 4 ? "active-link" : ""}
                      onClick={() => closeDrawer(4, "faq")}
                    >
                      Why Us?
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      className={activeAnch === 5 && "active-link"}
                      onClick={() => closeDrawer(5)}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                {activeNav && (
                  <span
                    className="cs_menu_toggle cs_toggle_active z-[20]"
                    onClick={() => setActiveNav(false)}
                  >
                    <span></span>
                  </span>
                )}
              </nav>
            </div>
            <div className="cs_main_header_right">
              <div className="cs_header_btns">
                <Link
                  className="cs_header_text_btn text-dark cs_modal_btn max-lg:hidden"
                  to="/login"
                >
                  Login
                </Link>
                <div className="max-lg:hidden">
                  <Link
                    to="/registration"
                    className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                    data-modal="register"
                  >
                    Register
                  </Link>
                </div>
              </div>
              {!activeNav && (
                <span
                  className="cs_menu_toggle"
                  onClick={() => setActiveNav(true)}
                >
                  <span></span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
      {/* <div class="flex flex-wrap place-items-center">
        <section class="relative mx-auto">
          <nav class="flex bg-[ba54f50000] bg-gradient-to-r from-violet-400 to-pink-400 text-dark w-screen shadow-b-lg px-4 flex items-center">
            <div className="shrink-0 ml-4">
              <Link
                to={"/"}
                className="block text-[000] text-2xl font-bold"
                aria-label="Cruip"
              >
                <img src={Logo} alt={"GDV PANEL"} width={"80%"} />
              </Link>
            </div>
            <div class="py-3 flex items-center w-full text-center">
              <div className="xl:text-3xl max-md:text-xl font-semibold  font-heading flex flex-row items-center justify-center text-center w-full">
                <span>{text}</span>
              </div>
            </div>
            <div
              class="px-5 xl:px-12 py-3 flex items-center w-fit"
              style={{ visibility: "hidden" }}
            >
              <Link to="/">
                <img src={Logo} alt="logo" className="w-14" />
              </Link>
            </div>
          </nav>
        </section>
      </div> */}
    </>
  );
};

export default Header;
