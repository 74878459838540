import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Loader from "../../views/Landing/Loader";
// import "react-modal-video/scss/modal-video.scss";
import thumbnail from "../../assets/landing/img/thumbnail.webp";
// import ModalVideos from "./modal-video";
import ModalVideo from "react-modal-video";

const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Others = ({ lang }) => {
  const [formData, setFormData] = useState({
    contact: "",
    name: "",
  });
  const [errors, setErrors] = useState({});
  const [overlay, setOverlay] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function handleChange() {
    setOpen(!isOpen);
  }

  

  const playerRef = useRef(null);

  const validate = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (formData.name.length < 3) {
      errors.name = "Name must be at least 3 characters";
    }

    if (!formData.contact) {
      errors.contact = "Phone number is required";
    } else if (!/^\d{9,15}$/.test(formData.contact)) {
      errors.contact = "Phone number must be between 9 and 15 digits";
    }

    return errors;
  };

  const onChange = (e) => {
    delete errors[e.target.name];
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeWithoutSpecialAndNumber = (e) => {
    delete errors[e.target.name];
    let newValue = e.target.value;
    const filteredValue = newValue.replace(/[^A-Za-z\s]/g, " ");
    setErrors(errors);
    setFormData({ ...formData, [e.target.name]: filteredValue });
  };

  // const playVideo = () => {
  //   if (playerRef.current) {
  //     playerRef.current.playVideo();
  //   }
  // };

  const HandleSubmit = async (e) => {
    console.log(errors, "errors");
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    let data = {
      name: formData.name,
      contact: formData.contact,
    };

    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoader(true);
        const res = await axios.post(`${BAS_URL_APPAPI}acharya/visitor`, data);

        if (res.data.success === 1) {
          setOverlay(false);
          setLoader(false);
        }
        // console.log(res, "hhujhjhj");
        //  setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormData({
          contact: "",
          name: "",
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  // useEffect(() => {

  //   if (playerRef.current) {
  //     if (playerRef.current.requestFullscreen) {
  //       playerRef.current.requestFullscreen();
  //     } else if (playerRef.current.mozRequestFullScreen) { // Firefox
  //       playerRef.current.mozRequestFullScreen();
  //     } else if (playerRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
  //       playerRef.current.webkitRequestFullscreen();
  //     } else if (playerRef.current.msRequestFullscreen) { // IE/Edge
  //       playerRef.current.msRequestFullscreen();
  //     }
  //   }

  // },[])

  return (
    <>
      {/* <ModalVideo /> */}
      <ModalVideo
              className="model-video"
              chanel="youtube"
              autoplay={1}
              loop="1"
              isOpen={isOpen}
              videoId="YrjbJajCDoI"
              onClose={handleChange}
            />
      <div className="cs_contact rounded-lg" id="contact">
        <div className="cs_height_150 cs_height_lg_80"></div>
        <section class="outter hero-video ">
          <section class="video-container relative">
            {overlay && <div className="video-container-overlay"></div>}
            {loader && <Loader />}
            {/* <ModalVideos
              className="video-container video"
              thumbWidth={1024}
              thumbHeight={576}
              thumbAlt="Modal video thumbnail"
              video="/videos/video.mp4"
              videoWidth={1920}
              videoHeight={1080}
              thumb={thumbnail}
            /> */}
            <img src={thumbnail} alt="" className="video-img" />
            <div
              className="absolute top-1/2 left-1/2 z-15 -translate-x-1/2 -translate-y-1/2"
            >
              <button
                className="group"
                onClick={handleChange}
                aria-label="Watch the video"
              >
                <svg
                  className="w-16 h-16 sm:w-20 sm:h-20 hover:opacity-75 transition duration-150 ease-in-out"
                  viewBox="0 0 88 88"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      x1="78.169%"
                      y1="9.507%"
                      x2="24.434%"
                      y2="90.469%"
                      id="a"
                    >
                      <stop stopColor="#EBF1F5" stopOpacity=".8" offset="0%" />
                      <stop stopColor="#EBF1F5" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle fill="url(#a)" cx="44" cy="44" r="44" />
                  <path
                    className="fill-current text-purple-600"
                    d="M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z"
                  />
                </svg>
              </button>
            </div>
            {
              <div
                class="callout wow"
                style={{ visibility: !overlay && "hidden" }}
                data-aos="fadeIn"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div
                  className="col-xl-8 wow"
                  data-aos="fadeIn"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                  <div className="cs_section_heading cs_style_1">
                    <h2 className="cs_section_title mb-0 text-[#fff] text-uppercase">
                      {lang === "eng" ? (
                        <strong className="text-white">Enter Details for Gdv Panel demo Video</strong>
                      ) : (
                        <strong className="text-white">
                          जीडीवी पैनल डेमो वीडियो के लिए विवरण दर्ज करें
                        </strong>
                      )}
                    </h2>
                  </div>
                  <div className="cs_height_50 cs_height_lg_40"></div>
                  <form id="cs_form" className="row" onSubmit={HandleSubmit}>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="name"
                        className="cs_form_field"
                        placeholder="Full Name*"
                        value={formData.name}
                        onChange={(e) => onChangeWithoutSpecialAndNumber(e)}
                      />
                      {errors.name && (
                        <div className="text-danger text-start">
                          {errors.name}
                        </div>
                      )}
                      <div className="cs_height_30 cs_height_lg_30"></div>
                    </div>
                    <div className="col-sm-6 video-field">
                      <input
                        type="number"
                        name="contact"
                        className="cs_form_field"
                        placeholder="Mobile*"
                        min="10"
                        value={formData.contact}
                        onChange={(e) => onChange(e)}
                      />
                      {errors.contact && (
                        <div className="text-danger text-start">
                          {errors.contact}
                        </div>
                      )}
                      <div className="cs_height_30 cs_height_lg_30"></div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                        type="submit"
                      >
                        Get Demo
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            }
          </section>
        </section>
        {/* <div className="cs_height_150 cs_height_lg_80 "></div> */}
      </div>
    </>
  );
};

export default Others;
