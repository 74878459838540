import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import user1 from "../../assets/landing/img/user.png";
import RatingStar from "views/Rating/RatingStar";

const Testimonials = ({ lang = "eng" }) => {

  const [currentSlide, setCurrentSlide] = useState(0);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    afterChange: (current) => setCurrentSlide(current/4),
    customPaging: (i) => (
      <div className="square">
        <div
          className={currentSlide === i ? "dots" : "dots-w"}
          onClick={() => setCurrentSlide(i)}
        ></div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          afterChange: (current) => setCurrentSlide(current/2),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          afterChange: (current) => setCurrentSlide(current/1),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          afterChange: (current) => setCurrentSlide(current/1),
        },
      },
    ],
  };

  const Testimonial = {
    eng: [
      {
        heading: "",
        sub_heading:
          "Testimonials: Here Is What Our Panelists, Acharyas, and Users Have to Say",
        points: [
          {
            img: user1,
            title: "Vaishali",
            city: "Agra",
            rating: 5,
            sub_title:
              "For a professional like me, the panel has proved to be a significant earning potential, especially during the Covid 19 pandemic, which left a major dent in my income stream. And it was a great platform to earn right from the comfort of my home.",
          },
          {
            img: user1,
            title: "Kiran Chawla",
            city: "Allahabad",
            rating: 5,
            sub_title:
              "Even if I put in only the slightest amount of effort, I earn almost around 35K monthly. This is amazing! I know I can take this bracket even to a new level, provided I invest a little bit extra time; the more I work, the higher my income will be.",
          },
          {
            img: user1,
            title: "Vinod Sharma",
            city: "Delhi",
            rating: 5,
            sub_title:
              "The engagement with this advanced astrology software has turned out to be a full-time profession for me. Moreover, it has job security for people like us; simply serve the people and earn to sustain your livelihood. It's a great tool for me!",
          },
          {
            img: user1,
            title: "Sangeeta",
            city: "Eta (U.P.)",
            rating: 5,
            sub_title:
              "Earlier, when analyzing the Kundali, I used to end up spending a lot of time. But now, my practice has become both simpler and faster than before. With this, the amount of effort has reduced manifold while benefiting my clients significantly.",
          },

          {
            img: user1,
            city: "Delhi",
            title: "Neeta Sharma",
            rating: 5,
            sub_title:
              "After association with this high-tech medium—one of the best Kundali software tools—my income has definitely increased. However, above all else, my confidence has elevated immensely. It is my saviour and a superb asset for me!",
          },
        ],
      },
    ],
    hin: [
      {
        heading: "",
        sub_heading: "प्रशंसापत्र: यहाँ हमारे पैनलिस्ट, आचार्य और उपयोगकर्ताओं का क्या कहना है",
        points: [
          {
            img: user1,
            title: "वैशाली",
            city: "आगरा",
            rating: 5,
            sub_title:
              "मेरे जैसे पेशेवर के लिए, पैनल ने महत्वपूर्ण कमाई की संभावना साबित की है, खासकर कोविड 19 महामारी के दौरान, जिसने मेरी आय के स्रोत में बड़ी कमी ला दी। और यह मेरे घर बैठे आराम से कमाई करने का एक बेहतरीन मंच था।",
          },
          {
            img: user1,
            title: "किरण चावला",
            city: "इलाहाबाद",
            rating: 5,
            sub_title:
              "भले ही मैं थोड़ी सी भी मेहनत करूँ, मैं हर महीने लगभग 35K कमा लेता हूँ। यह आश्चर्यजनक है! मुझे पता है कि मैं अपने इस ज्ञान को एक नए स्तर पर भी ले जा सकता हूँ, बशर्ते मैं थोड़ा अतिरिक्त समय लगाऊँ; जितना अधिक मैं काम करूँगा, मेरी आय उतनी ही अधिक होगी।",
          },
          {
            img: user1,
            title: "विनोद शर्मा",
            city: "दिल्ली",
            rating: 5,
            sub_title:
              "इस बेस्ट ज्योतिष सॉफ़्टवेयर के साथ जुड़ना मेरे लिए एक फुल-टाइम पेशा बन गया है। इसके अलावा, इसमें हमारे जैसे लोगों के लिए नौकरी की सुरक्षा है; बस लोगों की सेवा करें और अपनी आजीविका चलाने के लिए कमाएँ। यह मेरे लिए एक बढ़िया सॉफ्टवेयर है!",
          },
            {
            img: user1,
            city: "एटा (यू.पी.)",
            title: "गीता गुप्ता ",
            rating: 5,
            sub_title:
              "पहले, कुंडली का विश्लेषण करते समय, मैं बहुत समय लगाया करता था। लेकिन अब, मेरा अभ्यास पहले से कहीं ज़्यादा सरल और तेज़ हो गया है। इसके साथ ही, मेरे क्लाइंट को काफ़ी फ़ायदा पहुँचाते हुए, मेहनत की मात्रा कई गुना कम हो गई है।",
          },
          {
            img: user1,
            title: "नीता शर्मा",
            city: "दिल्ली",
            rating: 5,
            sub_title:
              "इस हाई-टेक माध्यम—सबसे बेहतरीन कुंडली सॉफ़्टवेयर टूल में से एक—से जुड़ने के बाद मेरी आय निश्चित रूप से बढ़ गई है। हालाँकि, इन सबसे बढ़कर, मेरा आत्मविश्वास काफ़ी बढ़ गया है। यह मेरा रक्षक और मेरे लिए एक बेहतरीन संपत्ति है!",
          },

        
        ],
      },
    ],
  };


  return (
    <section id="testimonial">
      <div className="cs_height_143 cs_height_lg_75"></div>
      {Testimonial[lang].map((main_item, index) => (
        <>
          <div className="container-custom" key={index}>
            <div className="cs_section_heading cs_style_1 text-center">
              <h2
                className="cs_section_title mb-0 wow"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                {" "}
                {main_item.sub_heading}
              </h2>
            </div>
            <div className="cs_height_85 cs_height_lg_60"></div>
          </div>
          <Slider {...settings} className="h-full container-custom">
            {main_item.points.map((e, index) => (
              <div key={index} style={{ width: "90%", display: "inline-block" }} className="h-full">
                <div className="card-inners shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
                  <div className="gd_client_info">
                    <div className="gd_client_img">
                      <img src={e.img} alt="avatar1" />
                    </div>
                    <div className="gd_client_meta">
                      <h4 className="gd_client_name">{e.title}</h4>
                      <span className="mb-0">{e.city}</span>
                    </div>
                  </div>
                  <p className="gd_client_review text-dark">{e.sub_title}</p>
                  <div className="gd_rating" data-rating="5">
                    <div
                      className="gd_rating_percentage"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <RatingStar rating={e.rating} />
                </div>
              </div>
            ))}
          </Slider>
        </>
      ))}
      <div className="cs_height_143 cs_height_lg_75"></div>
    </section>
  );
};

export default Testimonials;
