import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";

const Heading = ({ lang }) => {
  const [activeNav, setActiveNav] = useState(false);
  const [activeAnch, setActiveAnch] = useState(0);
  

  const closeDrawer = (event) => {
    setActiveAnch(event);
    setActiveNav(false);
  };

  const closeDrawerLangHindi = (event) => {
    lang("hin")
    setActiveNav(false);
  };

  const closeDrawerLangEng = (event) => {
    lang("eng")
    setActiveNav(false);
  };

  return (
    <header
      className="cs_site_header cs_style_1 cs_text_dark cs_sticky_header cs_medium cs_sticky_active bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0"
      style={{ backgroundColor: "transparent" }}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <div className="shrink-0 mr-4">
              <a
                href={"/"}
                className="block text-[#000] text-2xl font-bold"
                aria-label="Cruip"
              >
                <img src={logo} alt={"GDV PANEL"} width={"70%"} />
              </a>
            </div>
            <div className="cs_main_header_left w-fit">
              <nav className="cs_nav cs_medium cs_primary_font">
                <ul
                  className={`cs_nav_list cs_onepage_nav relative ${
                    activeNav ? "cs_active" : ""
                  }`}
                >
                  <div className="inline-flex rounded-md shadow-sm absolute top-6 left-[50%] right-[50%] lg:hidden">
                    <button
                      onClick={() => closeDrawerLangHindi()}
                      title="Hindi"
                      className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10"
                    >
                      हिं
                    </button>
                    <button
                      onClick={() => closeDrawerLangEng()}
                      title="English"
                      className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10"
                    >
                      En
                    </button>
                  </div>
                  <li className="max-lg:block lg:hidden">
                    <Link
                      to="/registration"
                      className="max-lg:block px-3 text-dark"
                      data-modal="register" 
                    >
                      Register
                    </Link>
                  </li>
                  <li className="max-lg:block lg:hidden">
                    <Link
                      className="cs_header_text_btn cs_modal_btn"
                      to="/login"
                    >
                      Login
                    </Link>
                  </li>
          
                  <li 
                  // className="menu-item-has-children"
                  >
                    <AnchorLink
                      href="#home"
                      className={activeAnch === 0 ? "active-link" : ""}
                      onClick={() => closeDrawer(0)}
                    >
                      Home
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      href="#features"
                      className={activeAnch === 1 ? "active-link" : ""}
                      onClick={() => closeDrawer(1)}
                    >
                      Features
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      href="#pricing"
                      className={activeAnch === 2 ? "active-link" : ""}
                      onClick={() => closeDrawer(2)}
                    >
                      Pricing
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      href="#testimonial"
                      className={activeAnch === 3 ? "active-link" : ""}
                      onClick={() => closeDrawer(3)}
                    >
                      Testimonial
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      href="#faq"
                      className={activeAnch === 4 ? "active-link" : ""}
                      onClick={() => closeDrawer(4)}
                    >
                      Why Us?
                    </AnchorLink>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      className={activeAnch === 5 && "active-link"}
                      onClick={() => closeDrawer(5)}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                {activeNav && (
                  <span
                    className="cs_menu_toggle cs_toggle_active z-[20]"
                    onClick={() => setActiveNav(false)}
                  >
                    <span></span>
                  </span>
                )}
              </nav>
            </div>
            <div className="cs_main_header_right">
              <div className="cs_header_btns">
                <div className="inline-flex rounded-md shadow-sm max-lg:hidden">
                  <button
                    onClick={() => closeDrawerLangHindi()}
                    title="Hindi"
                    className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:text-white"
                  >
                    हिं
                  </button>
                  <button
                    onClick={() => closeDrawerLangEng()}
                    title="English"
                    className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:text-white"
                  >
                    En
                  </button>
                </div>

                <Link
                  className="cs_header_text_btn text-dark cs_modal_btn max-lg:hidden"
                  to="/login"
                >
                  Login
                </Link>
                <div className="max-lg:hidden">
                  <Link
                    to="/registration"
                    className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                    data-modal="register"
                  >
                    Register
                  </Link>
                </div>
              </div>
              {!activeNav && (
                <span
                  className="cs_menu_toggle"
                  onClick={() => setActiveNav(true)}
                >
                  <span></span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Heading;
