import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import showcase from "../../assets/landing/img/showcase.webp";
import Polygon from "../../assets/landing/img/Polygon.svg";

const Hero = ({ lang = "eng" }) => {
  const heros = {
    eng: [
      {
        img: "",
        title:
          "Discover the Secrets of Stars with India’s Leading Astrology Software",
        sub_title:
          "With our advanced astrology software—highly productive yet user-friendly for acharyas, beginners, or enthusiasts—you can take your astrological practice to new heights with Gurudev GD Vashist's proven principles and insights!",
      },
    ],
    hin: [
      {
        img: "",
        title:
          "भारत के सर्वोत्तम ज्योतिष सॉफ्टवेयर के साथ करे ज्योतिषीय रहस्यों की खोज",
        sub_title:
          "हमारे कुंडली सॉफ्टवेयर के साथ, अनुभवी आचार्यों/ज्योतिषी या ज्योतिष विद्या सिखने में रूचि रखने वाले उत्साही लोगों के लिए अत्यधिक उपयोगी और अनुकूल है - आप गुरुदेव जीडी वशिष्ठ के असीमित ज्ञान, सिद्धांतों और अंतर्दृष्टि  के साथ अपने ज्योतिषीय अभ्यास को एक नयी ऊँचाइयों पर ले जा सकते है!",
      },
    ],
  };

  // console.log(heros["eng"], "heros");

  return (
    <section
      className="cs_hero cs_style_1 bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-90 shadow-inner-2xl"
      id="home"
    >
      <div className="container-custom">
        <div className="cs_hero_text text-center">
          {heros &&
            heros[lang].map((item,index) => (
              <div key={index}>
                <h1      
                  className="cs_hero_title cs_text_white wow fadeInUp  animate__animated"
                  data-aos="fadeInUp"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                  <b className="text-[#220000]">{item.title}</b>
                </h1>
                <p
                  className="cs_hero_subtitle text-[#220000]"
                  data-aos="fade-in"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                  {item.sub_title}
                </p>
              </div>
            ))}
        </div>
        <div
          className="cs_btn_group text-center wow"
          data-aos="fade-in"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          <AnchorLink href="#contact" className="cs_btn cs_bg_white">
            {lang && lang == "eng" ? "Get Started" : "आरंभ करें"}
          </AnchorLink>
        </div>
        {/* <div className="cs_height_100 cs_height_lg_60"></div> */}
        <div
          className="cs_hero_img wow"
          data-aos="fade-in"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          <img src={showcase} alt="Thumbnail" />
        </div>
      </div>
      <div className="cs_hero_shape1"></div>
      <div className="cs_hero_shape2">
        <img src={Polygon} alt="Polygon Icon" />
      </div>
      <div className="cs_hero_shape3"></div>
    </section>
  );
};

export default Hero;
