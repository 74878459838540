import React, { useEffect } from "react";
import { Button, CardBody } from "reactstrap";
import moment from "moment";
import Accordions from "components/Accordian/Accordians";

const Varshfal = ({
  index,
  result,
  centerValue,
  yearindex,
  item,
  questions,
  isLoading,
  HandleAnswers,
  setCenterValue,
  HandleQuestions,
  tabDisabled
}) => {

  
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    console.log(element, "scrolller");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  };


  function scrollContentCategory(direction) {
    const scrollContent = document.getElementById("myTab");
    console.log(scrollContent, "scrollContent");
    const scrollAmount = 150;
    scrollContent.scroll({ behavior: "smooth" ,inline: "center", block: "center",});
    if (direction === "left") {
      scrollContent.scrollLeft -= scrollAmount;
    } else if (direction === "right") {
      scrollContent.scrollLeft += scrollAmount;
    }
  }

  const centeredScrollVarshfal = (index, list, catId, indexs) => {
    console.log(list,"knnbknk")
    handleClickScroll(indexs);
    const [startDate, endDate] = list.split("to");
    const formattedStartDate = moment(startDate, "DD MMM YYYY").format(
      "DD-MM-YYYY"
    );
    const formattedEndDate = moment(endDate, "DD MMM YYYY").format(
      "DD-MM-YYYY"
    );
    if (formattedStartDate && formattedEndDate) {
      HandleQuestions(index, catId, formattedStartDate, formattedEndDate);
    }
    setCenterValue(indexs);
  };


  const getlist  = result && result[yearindex]
  // console.log(result[yearindex],"getlist")



  useEffect(() => {
    handleClickScroll(yearindex);
    centeredScrollVarshfal(index, getlist, item.categoryID, yearindex)
  }, []);

  return (
    <>
      <CardBody>
        <div
          role="tablist"
          id="myTab"
          className="scrolllist"
          style={{
            whiteSpace: "nowrap",
            scrollBehavior: "smooth",
            margin: "0 20px",
            overflowX: "scroll",
            overflowY: "hidden",
            display: "flex",
            gap: "8px",
            maxWidth: "100%",
          }}
        >
          {result && result.map((list, indexs) => (
            <button
              id={indexs}
              key={indexs}
              className={indexs == centerValue ? "tab_btn_focus bg-[#a78bfa] text-gray-900" : "tab_btn"}
              onClick={() =>
                centeredScrollVarshfal(index, list, item.categoryID, indexs)
              }
            >
              {list}
            </button>
          ))}
          <Button
            className="outline btn rounded-full"
            style={{
              padding: "7px 10px",
              position: "absolute",
              zIndex: "0",
              left: "14px",
              background:"#a78bfa",
              marginTop: "1px",
            }}
            onClick={() => scrollContentCategory("left")}
          >
            <i className="fa fa-arrow-left"></i>
          </Button>
          <Button
            className="outline btn rounded-full"
            style={{
              padding: "7px 10px",
              position: "absolute",
              zIndex: "0",
              right: "14px",
              background:"#a78bfa",
              marginTop: "1px",
            }}
            onClick={() => scrollContentCategory("right")}
          >
            <i className="fa fa-arrow-right"></i>
          </Button>
        </div>
        {/* </Tabs> */}
        <Accordions
          data={questions}
          isLoading={isLoading}
          tabnames={item.name}
          handleQuestionAns={HandleAnswers}
        />
      </CardBody>
    </>
  );
};

export default Varshfal;
